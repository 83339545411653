import React, { useState, useEffect } from 'react';
import './components.css';
import rev1 from '../images/rev1.jpg';
import rev2 from '../images/rev2.jpg';
import rev3 from '../images/rev3.jpg';
import rev4 from '../images/rev4.jpg';
import rev5 from '../images/rev5.jpg';
import rev6 from '../images/rev6.jpg';
import rev7 from '../images/rev7.jpg';

const SlideShow = ({ images }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
      }, 3000);
   
      return () => clearInterval(interval);
    }, [images.length, currentSlide]);
   
    useEffect(() => {
      const slidesContainer = document.querySelector('.slides');
      if (slidesContainer) {
        slidesContainer.style.transform = `translateX(-${currentSlide * (100 + 30)}px)`;
      }
    }, [currentSlide]);
    return (
      <section>
         
          <div className="container-fluid ">
      <div className="slideshow-container">
        <div className="slides">
          {images.map((image, index) => (
            <div
              key={index}
              className={`slide ${index === currentSlide ? 'active' : ''}`}
            >
              <img
                src={image.src}
                className="slide-image"
                alt="slide"
              />
            </div>
          ))}
        </div>
      </div>
      </div>
    
      </section>
    );
          };

export const Review = () => {


    const images = [   
        { src: rev1},
        { src: rev2 },
        { src: rev3},
        { src: rev4 },
        { src: rev5},
        { src: rev6 },
        { src: rev7},
         
        
          ];
  return (
    <div id="testimonial" className="p-3 review" style={{backgroundColor:'#014aad' ,alignItems:'center'}}>
  <h1 className='p-4 text-white text-start' style={{fontWeight:'normal'}}>#we work hard<br></br>to satisfy our clients</h1>
    <div  style={{width:'100%'}}>
<SlideShow images={images} />
</div>
</div>
  )
}
