import React from 'react';
import whatsapp from '../images/whatsapp.png';
import './components.css';


export const SideButton = () => {
    
  return (
    <div >
      <li className='side-btn' style={{
            position: 'fixed',
            bottom: '10px',
            right: '0px',
            zIndex: '1000',
            cursor: 'pointer',
            listStyle:'none',
            
           
            
          }}>
          <a href="https://api.whatsapp.com/send/?phone=918971960587&text&type=phone_number&app_absent=0" 
        target="_blank" rel="noreferrer" > <img src={whatsapp} alt="whatsapp" width="70px" height="auto"/></a>
          </li> 
    </div>
  )
}
