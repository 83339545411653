import React from 'react';
import ourpromise from '../images/our promise.jpg';
import './components.css';


export const OurPromise = () => {
  return (
    <div> 
    <div className="mt-4">
<section className=' sec1 d-lg-flex w-100'>
    <div className='sec1_img p-5' >
<img className="mt-4" src={ourpromise} alt="promss" width="470px" height="450px" style={{boxShadow: '4px 4px 4px 4px rgba(200,0,0,0.2)'}} ></img>
    </div>
    <div className='sec1_p'  >
        <h1>Our Promise To You!</h1>
<p className="pt-3"><i class="bi bi-check-circle-fill" style={{color:'#2060ac',fontSize:'18px',paddingRight:"5px"}}></i> Every employee undergoes a thorough background verification process.</p>
<p><i class="bi bi-check-circle-fill" style={{color:'#2060ac',fontSize:'18px',paddingRight:"5px"}}></i>Family and Individual maids available from all over nation.</p>
<p><i class="bi bi-check-circle-fill" style={{color:'#2060ac',fontSize:'18px',paddingRight:"5px"}}></i> We take monthly feedback from clients / as well maids.</p>
<p><i class="bi bi-check-circle-fill" style={{color:'#2060ac',fontSize:'18px',paddingRight:"5px"}}></i> Contracts are offered from one hour to 24 hours.</p>
<p><i class="bi bi-check-circle-fill" style={{color:'#2060ac',fontSize:'18px',paddingRight:"5px"}}></i> Replacements provided within 48 hours.</p>
<p><i class="bi bi-check-circle-fill" style={{color:'#2060ac',fontSize:'18px',paddingRight:"5px"}}></i> 24/7 customer support.</p>
    </div>
</section>
    </div>

    </div>
  )
}
