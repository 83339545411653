import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))
(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'white'
      ? '#FFFFFF'
      : '#1b7dff',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className='p-5' style={{ width: "100%" }}>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{ width: '100%' }}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography style={{color:"white"}}>How can I book a housemaid service with Harrvi Man Power Solutions?</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: '#FFFFFF' }}>
          <Typography>
          Booking a housemaid service with us is simple. You can contact us through our website, phone, or email to discuss your requirements. Our team will provide you with the best options to suit your needs, whether you require a maid for a few hours or full-time assistance.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} style={{ width: '100%' }}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography style={{color:"white"}}>What are the working hours for your maid services?</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: '#FFFFFF' }}>
          <Typography>
          Our maid services are highly flexible. We offer services that range from 1 hour to 24 hours a day, tailored to meet individual and family needs. Whether you need part-time help or full-time assistance, we can accommodate your schedule.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} style={{ width: '100%' }}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography style={{color:"white"}}>What are the benefits of using Harvvi Man Power Solutions for facility management?</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: '#FFFFFF' }}>
          <Typography>
          Our facility management services offer numerous benefits, including professional and reliable staff, customized service plans, comprehensive solutions (drivers, security, housekeeping, etc.), and consistent quality. We take care of all your facility management needs, allowing you to focus on your core activities.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} style={{ width: '100%' }}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography style={{color:"white"}}>Do you offer deep cleaning services for both commercial and residential properties?</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: '#FFFFFF' }}>
          <Typography>
          Yes, we provide deep cleaning services for both commercial and residential properties. Our experienced team uses advanced equipment and eco-friendly cleaning products to ensure thorough and effective cleaning, leaving your space spotless and hygienic.          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} style={{ width: '100%' }}>
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <Typography style={{color:"white"}}>Can you provide a quick household tip?</Typography>
        </AccordionSummary >
        <AccordionDetails style={{ backgroundColor: '#FFFFFF' }}>
          <Typography>
          Sure! Here’s a quick tip: To keep your kitchen sink smelling fresh, grind lemon peels in the garbage disposal. The natural oils in the lemon will help eliminate odors and leave a pleasant, fresh scent.          </Typography>
        </AccordionDetails>
      </Accordion>
      
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} style={{ width: '100%' }}>
        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
          <Typography style={{color:"white"}}>Do you provide 24/7 support for your services?</Typography>
        </AccordionSummary >
        <AccordionDetails style={{ backgroundColor: '#FFFFFF' }}>
          <Typography>
          <h5>Yes, We Provide 24/7 Support</h5>
          At Harrvi Man Power Solutions, we offer round-the-clock support for all our services, ensuring your needs are met anytime, day or night..
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}