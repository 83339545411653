import React, { useState } from 'react'
import './AppBar.css';
import logo from '../images/logo.png';


function DrawerAppBar() {
  const [click, setClick] = useState(false);

  const handleClick = () =>setClick(!click);
  const closeMobileMenu = () =>setClick(false);
  return (
    <div>
    <app className="appbar" display="fixed" >
      <div className="appbar-container">
       
        <a href="#home" className='appbar-logo pb-4' >
            <img src={logo} alt="Logo" style={{width:'150px',maxWidth:'300px',minWidth:'150px'}} />
           
          </a>
       
        <div className="d-lg-none menu-icon" onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className='app-item' >
            <a href='#home' className='app-item' onClick={closeMobileMenu} >
              Home
            </a>
          </li>
          <li className='app-item'>
            <a href="#about" class='app-item' onClick={closeMobileMenu}>
              About
            </a>
          </li>
          <li className='app-item' >
            <a  href="#services" className="app-item" onClick={closeMobileMenu}>
            Services
            </a>
          </li>
          <li className='app-item'>
            <a href='#testimonial' className='app-item' onClick={closeMobileMenu}>
            Testimonial
            </a>
          </li>
          <li className='app-item' >
            <a href='#faq' className='app-item' onClick={closeMobileMenu} >
              Faq
            </a>
          </li>
          <li className='app-item'>
            <a href='#contact' className='app-item' onClick={closeMobileMenu}>
              Contact Us
            </a>
          </li> 
         
         
        </ul>
      </div>
    </app>
    </div>
  )
}

export default DrawerAppBar
