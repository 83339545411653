import React from "react";
import hall from "../images/home.jpg"
import clean from "../images/harrvi1.jpg"
import reliable from "../images/reliable1.png"
import expert from "../images/expert1.png"
import eco from "../images/eco1.png"
import './home.css';
import Section from '../section';
import {Typewriter} from "react-simple-typewriter"
import Accordian from "../components/Accordian"
import Contact from "./Contact";

export default function Home() {
  const containerStyle = {
    backgroundImage: `url(${hall})`,
    backgroundSize: "cover",
   
  };

  const cardData = [
    { title: "Expert Cleaners", tag:"Our team ensures a spotless environment with meticulous attention to detail.", image:expert },
    { title: "Reliable Service", tag:"Consistent, high-quality cleaning solutions you can count on.", image:reliable },
    { title: "Eco-Friendly", tag:"We use environmentally safe products for a healthier home.", image:eco },
  ];

  return (
    <div  style={{overflowX:"hidden", paddingTop:"90px"}}>
      <div style={{ backgroundColor: "white" }}>
        <div id="home" style={containerStyle}>
          <div className="div">
            <h1 className="home-h1">
        NEED <br/> ASSISTANCE AT<br/>HOME / WORKPLACE...?<br/>
          </h1>
           <h3 className="text- text-start text-bold" style={{color:"tomato",fontWeight:'normal'}}>
                Are You Looking For{' '} 
                
                <span  className="moving text" style={{ color: '#1b7dff', fontSize:"30px",fontWeight:'600'}}>
                    <Typewriter
                        words={['Skilled Housemaid', 'Expert Cook', 'Reliable Drivers', 'Caregiver', 'Trained Security']}
                        loop={0} // Set to 0 for infinite loop
                        // cursor
                        cursorStyle='_'
                        typeSpeed={70}
                        deleteSpeed={50}
                        delaySpeed={2000}
                    />
                </span> 
                
                </h3>
          <p  className=" pt-5 text-start " style={{color:"#eee", letterSpacing:"1px",fontStyle:"oblique",fontSize:'18px'}}>#EXPERT CARE FOR EVERY NEEDS!!</p>
          </div>

        </div>
      </div>

     

     <div id="about" >
       <h1 style={{textAlign:"center", paddingTop:"40px"}}>About Us</h1>
        <section className="p-5 d-md-flex w-100" >
       <div>
         <img src={clean} alt="Home"  className="about-image"/>
       </div>
     
        <div className=" about-text">
          {/* <h2 style={{color:"#146C64"}}>#Who We Are</h2> */}
          {/* <strong style={{color:"#014aad"}}>#Who We Are</strong><br/> */}
          <p className="p-2"  style={{textAlign:"justify"}}><strong style={{color:"#014aad"}}>At Harrvi Man Power Solutions,</strong> <br></br>We pride ourselves on being the leading provider of exceptional 
         <strong style={{color:"#1b7dff", fontWeight:"normal"}}> housekeeping, housemaids, deep cleaning, and total facility management.</strong> Our mission is to deliver unparalleled support, 
          ensuring your home remains a clean, organized, and welcoming space. Whether you need dedicated housemaids 
          for baby care, patient care, cooking, or general household work, our skilled team is here to help.
       
        <br></br>We offer comprehensive facility management services, including reliable drivers, vigilant security, and thorough deep cleaning for both commercial and residential spaces. 
        Our flexible maid services range from as little as one hour to around-the-clock assistance, tailored to meet your unique needs.

         <br></br>We understand the challenges of maintaining a pristine household, and our 24/7 support guarantees that help is always just a call away. At Harvvi Man Power Solutions, we are 
         committed to providing top-notch housekeeping and housemaid services that bring peace of mind and enhance your daily life.</p>
          
        </div>
        </section>
        <div   style={{width:"15%", height:"5px", backgroundColor:"tomato", borderRadius:"5px"}}></div>
        <div className="mt-5 mb-5 card-container d-sm-flex p-2" >
           {cardData.map((card, index) => (
          <div key={index} className={`card ${index === 1 ? 'highlight' : ''}`}>
            <img src={card.image} alt="Avatar" className="avatar" />
            <div className="container">
              <h4><b>{card.title}</b></h4>
              <p>{card.tag}</p>
            </div>
          </div>
        ))}
      </div>
      <div  style={{width:"15%", height:"5px", backgroundColor:"tomato", position:'absolute',right:'0px',borderRadius:"5px"}}></div>

        
       </div>
       <Section/>
       <div  id="faq" className="d-md-flex pt-2" style={{justifyContent:"space-between"}}>
        <div className="qtns" >
        <h1 className="pt-5"><i class="bi bi-patch-question" style={{color:"tomato", fontSize:"55px"}}></i> Frequently asked questions</h1>
        <p>Queries related to the pricing, and availability of a product or service.</p>
        </div>
        <Accordian/>
       </div>
       <div id="contact">
       <Contact/>
       </div>
    </div>
  );
}
