import React ,{ useRef} from "react";
import { useInView} from "framer-motion";
import './section.css';
import { OurServices } from "./components/OurServices";
import { OurPromise } from "./components/OurPromise";
import { FiveStep } from "./components/FiveStep";
import { Review } from "./components/Review";









function Section({ children }) {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });
   
    return (
      <section ref={ref}>
        <span
          style={{
            transform: isInView ? "none" : "translateX(-200px)",
            opacity: isInView ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.4s"
          }}
        >
          {children}
        </span>
      </section>
    );
  }
  export default function Children(){
    return(
        <>
        <Section>
           <OurServices/>
        </Section>
     
        
        <Section>
            <FiveStep/>
        </Section>
        <Section>
            <OurPromise/>
        </Section>
        <Section>
           <Review/>
        </Section>
       
      
       </>
    )
  }