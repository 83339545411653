import React from 'react';
import './components.css';
import step1 from '../images/step1.png';
import step2 from '../images/step2.png';
import step3 from '../images/step3.png';
import step4 from '../images/step4.png';
import step5 from '../images/step5.png';
import icon1 from '../images/1icon.png';
import icon2 from '../images/2icon.png';
import icon3 from '../images/3icon.png';
import icon4 from '../images/4icon.png';
import icon5 from '../images/5icon.png';
import rightarrow from '../images/rightarrow.png';




export const FiveStep = () => {
  return (
    <div>
        <h1 className=' promss-heading text-center'>Our 5-Step <br></br>Process</h1>
        <img src={rightarrow} alt="arrow" width="300px" height="30x"></img>
       <div className='d-md-flex p-3' >
       <section >
       <img src={icon1} alt="icon1" width="60px"></img>
       <div className="d-flex" style={{justifyContent:'center'}}>
        <img src={step1} alt="step1" width="20px" height="20px"></img>
        <h6>Requirement Gathering</h6>
        </div>
        <p>Detailed discussion to understand your needs.</p>
       </section>
       <section className='step2' >
       <img src={icon2} alt="icon2" width="60px"></img>
       <div className='d-flex' style={{justifyContent:'center'}}>
        <img src={step2} alt="step2" width="20px" height="20px"></img>
        <h6> Verification</h6>
        </div>
        <p>Comprehensive verification of candidates’ credentials.</p>
       </section>
       <section className="step3">
       <img src={icon3} alt="icon3" width="60px"></img>
       <div className='d-flex' style={{justifyContent:'center'}}>
        <img src={step3} alt="step3" width="20px" height="20px" style={{paddingRight:'3px'}}></img>
        <h6> Shortlisting</h6>
        </div>
        <p>Selecting the best candidates from our extensive database.</p>
       </section>
       <section  className="step4">
       <img src={icon4} alt="icon4" width="60px"></img>
       <div className='d-flex' style={{justifyContent:'center'}}>
        <img src={step4} alt="step4" width="20px" height="20px" style={{paddingRight:'3px'}}></img>
        <h6> Interviewing</h6>
        </div>
        <p>Conducting interviews to ensure suitability.</p>
       </section>
       <section >
       <img src={icon5} alt="icon5" width="60px"></img>
       <div className='d-flex ' style={{justifyContent:'center'}}>
        <img src={step5} alt="step5" width="20px" height="20px" style={{paddingRight:'3px'}}></img>
        <h6 > Deployment</h6>
        </div>
        <p>Aligning candidates with your requirements for final approval</p>
       </section>
       </div>
      

     

    </div>
  )
}
