import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AppBar from './components/AppBar'
import './App.css';
import Footer from './components/Footer';
import Home from './components/Home';
import Contact from './components/Contact';
import { SideButton } from './components/SideButton';

function App() {
  return (
    <div className="App">
      <AppBar/>
      <SideButton/>
      <Routes>
        <Route path='/' element={<Home/>}></Route>
        
        <Route path='/contactus' element={<Contact/>}></Route>


      </Routes>
    <Footer/>
    </div>
  );
}

export default App;
