import React from 'react';
import './components.css';
import harrvi1 from '../images/1.png';
import harrvi2 from '../images/2.png';
import harrvi3 from '../images/3.png';
import harrvi4 from '../images/4.png';
import blockimg from '../images/blockimg.jpg';



export const OurServices = () => {
  return (
<>
    <div id="services">    
<h1 className=' pt-4 text-dark '>Our Services</h1>
<section className='sec d-md-flex w-100'>
<div >
<img className='sec_img' src={harrvi1} alt="1" ></img>

</div>
<div className='pt-5'>
<h3>Housemaids</h3>
<p className="sec_p">We provide skilled housemaids for various needs, including baby care, patient care, and household chores.Our rigorous training programs and support ensure you receive reliable and dedicated service.</p>
</div>
</section>
<section className='sec d-md-flex w-100'>
<div className='pt-5'>
<h3>Deep Cleaning</h3>
<p className="sec_p">Our deep cleaning services use advanced techniques and equipment to remove dirt, stains, and allergens. We focus on improving indoor air quality and ensuring a thorough clean that leaves your space refreshed and hygienic.</p>
</div>
<div >
<img className='sec_img' src={harrvi2} alt="1"></img>
</div>
</section>
<section className='sec d-md-flex w-100'>
<div >
<img className='sec_img' src={harrvi3} alt="1"></img>
</div>
<div className='pt-5'>
<h3>Housekeeping</h3>
<p className="sec_p">Our professional housekeeping services ensure consistent, high-quality cleaning with trained and reliable staff. We handle the supervision, so you can focus on what matters most.</p>
</div>
</section>
<section className='sec d-md-flex w-100'>
<div className='pt-5'>
<h3>Total Facility Management</h3>
<p className="sec_p">Our facility management services encompass a range of solutions, including drivers, security, and maintenance. We provide a comprehensive approach to managing your facility efficiently, reducing costs and enhancing operational effectiveness.</p>
</div>
<div >
<img className='sec_img' src={harrvi4} alt="1"></img>
</div>
</section>


<div className='m-4'>
  <section className='d-md-flex' style={{backgroundColor:'#014aad'}}>
 
    <img src={blockimg} alt="img" height="450px"   style={{transform:'skew(-17deg)'}}></img>
    <div>
      <div>
<h1 className=' p-5 text-white text-start text-size'>Comprehensive Housekeeping Solutions <br></br>Tailored For You</h1>
<h6 className='text-start' style={{paddingLeft:'50px',paddingBottom:'20px'}}>From homes to offices, we provide comprehensive <br></br>housekeeping services customized to your needs</h6>
</div>
    </div>
  </section>
</div>
    </div>


    </>
  )
}
