import React from "react";
import "./contact.css";
import contact from "../images/contact3.jpg";


export default function Contact() {
    const containerStyle = {
        backgroundImage: `url(${contact})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        
     
        
      };
      
  return (
  
    
        

        <div className="p-4"  style={containerStyle}>
          <h2 className="text-white">Feel free to contact us !</h2>
           <div>
            <form
              className="contact-form"
              action="https://formspree.io/f/xrbzpddy"
              method="POST"
            >
              <input
                type="text"
                name="username"
                placeholder="Name"
                autoComplete="off"
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                autoComplete="off"
                required
              />
              <input
                type="number"
                name="number"
                placeholder="Contact Number"
                autoComplete="off"
                required 
              />
              {/* <input type='text' name='username' placeholder='username' autoComplete='off' required/> */}
              <textarea
                name="message"
                placeholder="Purpose Of Contact"
                cols="30"
                rows="5"
                autoComplete="off"
                required
              />
              <input type="submit" value="Send" className="submit-button" />
            </form>
          </div>
        </div>
     
   
  );
}
